import { DocumentReference, Timestamp } from 'firebase/firestore';
import _ from 'lodash';
import moment from 'moment';
import { ppMomentDateTime } from '../utils/Formatter';
import { Cart, ICart } from './cart';
import { IPayment, Payment } from './payment';
import { DocumentModel, FirestoreTimestamp } from './utils/document-model';
import {
  documentReference2String,
  string2DocumentReference,
} from './utils/document-reference-serializer';

interface ISale {
  barcodes?: string[];
  cart?: ICart;
  customer?: DocumentReference | string;
  drawer?: DocumentReference | string;
  drawerId?: string;
  drawerName?: string;
  payments?: IPayment[];
  refunds?: (DocumentReference | string)[];
  salesOrder?: DocumentReference | string;
  sonumber?: string;
  totalCash?: number;
  totalCredit?: number;
  totalDebit?: number;
  totalCheque?: number;
  transactionId?: string;
  transactionIdReverse?: string;
  voided?: boolean;
  createdTime?: FirestoreTimestamp;
  updatedTime?: FirestoreTimestamp;
}

export class Sale extends DocumentModel<ISale> implements ISale {
  readonly barcodes: string[];
  readonly cart: Cart;
  readonly customer: string;
  readonly drawer: string;
  readonly drawerId: string;
  readonly drawerName: string;
  readonly payments: Payment[];
  readonly refunds: string[];
  readonly salesOrder: string;
  readonly sonumber: string;
  readonly totalCash: number;
  readonly totalCredit: number;
  readonly totalDebit: number;
  readonly totalCheque: number;
  readonly transactionId?: string;
  readonly transactionIdReverse?: string;
  readonly voided: boolean;
  readonly createdTime?: FirestoreTimestamp;
  readonly updatedTime?: FirestoreTimestamp;

  constructor(id: string, data: ISale) {
    super(id, data);
    this.barcodes = data.barcodes ?? [];
    this.cart = new Cart(data.cart);
    this.customer = documentReference2String(data.customer);
    this.drawer = documentReference2String(data.drawer);
    this.drawerId = data.drawerId ?? '';
    this.drawerName = data.drawerName ?? '';
    this.payments = data.payments?.map((payment) => new Payment(payment)) ?? [];
    this.refunds =
      data.refunds?.map((refund) => documentReference2String(refund)) ?? [];
    this.salesOrder = documentReference2String(data.salesOrder);
    this.sonumber = data.sonumber ?? '';
    this.totalCash = data.totalCash ?? 0;
    this.totalCredit = data.totalCredit ?? 0;
    this.totalDebit = data.totalDebit ?? 0;
    this.totalCheque = data.totalCheque ?? 0;
    this.transactionId = data.transactionId ?? '';
    this.transactionIdReverse = data.transactionIdReverse ?? '';
    this.voided = data.voided ?? false;
    this.createdTime = data.createdTime;
    this.updatedTime = data.updatedTime;
  }

  isRefund() {
    return this.cart.cartItems.some((item) => item.quantity < 0);
  }

  hasRefund() {
    return !!this.refunds.length;
  }

  isVoidedOrRefund() {
    return this.voided || this.isRefund();
  }

  toFirestore() {
    const iSale = super.toFirestore();
    // Convert path strings back into firestore reference.
    if (this._modifiedProperties.customer) {
      iSale.customer = string2DocumentReference(this.customer);
    }
    // Convert path strings back into firestore reference.
    if (this._modifiedProperties.drawer) {
      iSale.drawer = string2DocumentReference(this.drawer);
    }
    // Convert path strings back into firestore reference.
    if (this._modifiedProperties.salesOrder) {
      iSale.salesOrder = string2DocumentReference(this.salesOrder);
    }
    // Convert path strings back to firestore reference.
    if (this._modifiedProperties.refunds) {
      iSale.refunds = this.refunds.map((refund) =>
        string2DocumentReference(refund)
      );
    }
    // Override server side createdTime with client for Transaction Id.
    if (this._modifiedProperties.createdTime) {
      iSale.createdTime = this.createdTime;
      // Generate transaction id for search purposes.
      iSale.transactionId = (this.createdTime as Timestamp)
        .toDate()
        .getTime()
        .toString();
      iSale.transactionIdReverse = iSale.transactionId
        .split('')
        .reverse()
        .join('');
      const bcds =
        iSale.cart!.cartItems?.map((item) => {
          return [
            item.product?.barcode ?? '',
            ...(item.product?.barcodes ?? []),
          ];
        }) ?? [];
      iSale.barcodes = _.uniq(_.flatMap(bcds, (value) => value));
      console.log(iSale.barcodes);
    }
    // Prevent explosion of document size. This is because Department has Products as a field.
    if (this._modifiedProperties.cart && iSale.cart?.cartItems) {
      iSale.cart.cartItems.forEach((cartItem) => {
        cartItem.departments?.forEach((department) => {
          department.products = [];
        });
      });
    }
    return iSale;
  }

  calculateTotalPaymentWithoutAR() {
    return this.payments.reduce((prev, curr) => {
      const cashReturned =
        curr.totalCashReturned < 0 ? 0 : curr.totalCashReturned; // totalCashReturned can be negative for split payment
      const totalPayed = curr.type === 'PaymentType.AR' ? 0 : curr.totalPayed; // 0 out AR for calculating total payment.
      return (prev += _.round(totalPayed, 2) - _.round(cashReturned, 2));
    }, 0);
  }

  calculateTotalPayment() {
    return this.payments.reduce((prev, curr) => {
      const cashReturned =
        curr.totalCashReturned < 0 ? 0 : curr.totalCashReturned; // totalCashReturned can be negative for split payment
      return (prev += _.round(curr.totalPayed, 2) - _.round(cashReturned, 2));
    }, 0);
  }

  getTransactionTimestampId() {
    try {
      return (this.createdTime as Timestamp).toDate().getTime().toString();
    } catch (error) {
      return 'Transaction id not found.';
    }
  }

  getCreatedTime() {
    try {
      return ppMomentDateTime(
        moment((this.createdTime as Timestamp).toDate()),
        'MMM D YYYY, h:mm:ss A'
      );
    } catch (error) {
      return ppMomentDateTime(moment(), 'MMM Do YYYY, h:mm:ss A');
    }
  }
}
