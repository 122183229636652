import { Button, Input, InputNumber, Layout, List, Modal, Row } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Title from 'antd/lib/typography/Title';
import { addDoc, serverTimestamp } from 'firebase/firestore';
import { useContext, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Drawer } from '../../../../models/drawer';
import { DrawerContext } from '../../../../providers/base/DrawerProvider';
import { Repository } from '../../../../providers/base/generics/GenericContextProvider';
import { ppPrice } from '../../../../utils/Formatter';
import { DollarInputNumberFormatter } from '../../../common/detail/cards/card-styles';
import { Notifications } from '../../../notification/Messages';
import { DrawerTile } from './DrawerTile';

const RegisterCount = ({
  history,
  url,
  drawerRepository,
}: {
  history: any;
  url: string;
  drawerRepository?: Repository<Drawer>;
}) => {
  const [initialAmount, setInitialAmount] = useState(0);
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  return (
    <>
      <List>
        <List.Item>
          <List.Item.Meta title='Register name' />
          <Input
            value={name}
            style={{ width: '150px' }}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta title='Opening amount' />
          <InputNumber
            value={initialAmount}
            formatter={DollarInputNumberFormatter}
            onChange={(value) => {
              setInitialAmount(value);
            }}
            style={{ width: '150px' }}
            min={0}
            precision={2}
          />
        </List.Item>
      </List>
      <Row justify='end' style={{ marginTop: 16 }}>
        <Button
          loading={loading}
          block
          type='primary'
          onClick={async () => {
            try {
              setLoading(true);
              if (drawerRepository && initialAmount > 0) {
                const drawer = new Drawer('', {
                  actualCash: -1,
                  name: name,
                  initialCash: initialAmount,
                  createdTime: serverTimestamp(),
                });
                const snap = await addDoc(
                  drawerRepository.collection(),
                  drawer
                );
                history.push(`${url}/${snap.id}`);
              } else {
                history.push(`${url}/none`);
              }
            } catch (error) {
              Notifications.error(error);
              setLoading(false);
            } finally {
              Modal.destroyAll();
            }
          }}
        >
          Start {`with ${ppPrice(initialAmount)}`}
        </Button>
      </Row>
    </>
  );
};

const StartDrawerDialog = (
  history: any,
  url: string,
  drawerRepository?: Repository<Drawer>
) => {
  const modal = Modal.success({
    centered: true,
    closable: true,
    maskClosable: true,
    title: 'Opening Count',
    okText: null,
    icon: null,
    okButtonProps: {
      style: {
        display: 'none',
      },
    },
    content: (
      <RegisterCount
        history={history}
        url={url}
        drawerRepository={drawerRepository}
      />
    ),
  });
};

const DrawersList = () => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const {
    snapshots: drawerSnapshots,
    repository: drawerRepository,
    isLoading,
  } = useContext(DrawerContext);

  return (
    <Layout>
      <Content>
        <Row justify='space-between'>
          <Title level={3}>Registers</Title>
          <Button
            type='primary'
            onClick={() => StartDrawerDialog(history, url, drawerRepository)}
          >
            Open new register
          </Button>
        </Row>
        <List
          loading={isLoading}
          grid={{
            gutter: 16,
            xs: 1,
            sm: 1,
            md: 1,
            lg: 2,
            xl: 3,
            xxl: 4,
          }}
          dataSource={drawerSnapshots.map((snap) => snap.data())}
          renderItem={(drawer) => {
            return <DrawerTile drawer={drawer} history={history} url={url} />;
          }}
        ></List>
      </Content>
    </Layout>
  );
};

export { DrawersList };
