import { Button, Modal, Row, Select, Typography } from 'antd';
import {
  arrayUnion,
  doc,
  increment,
  Timestamp,
  writeBatch,
} from 'firebase/firestore';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../firebase/Auth';
import { masterRepository } from '../../../firebase/db';
import { db } from '../../../firebase/Fb';
import { Cart } from '../../../models/cart';
import { Payment, PaymentType } from '../../../models/payment';
import { Sale } from '../../../models/sale';
import {
  DrawerContext,
  DrawerProvider,
} from '../../../providers/base/DrawerProvider';
import {
  SaleContext,
  SaleProvider,
} from '../../../providers/pagination/SaleProvider';
import { ppPaymentType, ppPrice } from '../../../utils/Formatter';
import { Messages, Notifications } from '../../notification/Messages';
import { CartList } from '../register/CartList';
import { totalAmountByType } from '../register/ProductCart';
const { Text } = Typography;

const RefundButton = () => {
  const { currentCompany, currentStore } = useContext(AuthContext);
  const {
    snapshots: drawerSnapshots,
    repository: drawerRepository,
    isLoading: isDrawerLoading,
  } = useContext(DrawerContext);
  const { snapshot, isLoading } = useContext(SaleContext);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [refundCart, setRefundCart] = useState<Cart>(new Cart());
  const [paymentType, setPaymentType] =
    useState<PaymentType>('PaymentType.Cash');
  const [drawerId, setDrawerId] = useState('');
  const [payment, setPayment] = useState<Payment>(new Payment());
  const history = useHistory();

  useEffect(() => {
    if (!isLoading && snapshot?.exists()) {
      const sale = snapshot.data();
      sale.cart.cartItems.forEach((item) => {
        item.quantity = 0;
      });
      setRefundCart(sale.cart);
    }
  }, [snapshot, isLoading]);

  useEffect(() => {
    const total = refundCart.calculateTotal();
    const refund = new Payment({
      totalCashReturned: 0,
      totalPayed: total * -1,
      type: paymentType,
    });
    setPayment(refund);
  }, [refundCart, paymentType]);

  if (isLoading) {
    return <></>;
  } else if (!snapshot?.exists()) {
    return <></>;
  }

  const sale = snapshot.data();

  const processRefund = async () => {
    try {
      setConfirmLoading(true);
      const batch = writeBatch(db);

      // Update drawer if required.
      if (drawerId) {
        const totalCashAmount = totalAmountByType(
          [payment],
          'PaymentType.Cash'
        );
        const totalCreditAmount = totalAmountByType(
          [payment],
          'PaymentType.Credit'
        );
        const totalDebitAmount = totalAmountByType(
          [payment],
          'PaymentType.Debit'
        );
        const totalChequeAmount = totalAmountByType(
          [payment],
          'PaymentType.Cheque'
        );
        batch.update(drawerRepository!.document(drawerId), {
          salesCash: increment(totalCashAmount),
          salesCredit: increment(totalCreditAmount),
          salesDebit: increment(totalDebitAmount),
          salesCheque: increment(totalChequeAmount),
        });
      }

      // Refund sale
      const refundCartFiltered = _.cloneDeep(refundCart);
      refundCartFiltered.cartItems = refundCartFiltered.cartItems.filter(
        (item) => item.quantity > 0
      );
      refundCartFiltered.cartItems.forEach((item) => {
        item.quantity = item.quantity * -1;
      });
      const drawerRef = drawerId
        ? drawerSnapshots.find((snap) => snap.id === drawerId)
        : undefined;
      const totalCash =
        payment.type === 'PaymentType.Cash' ? payment.totalPayed : 0;
      const totalCredit =
        payment.type === 'PaymentType.Credit' ? payment.totalPayed : 0;
      const totalDebit =
        payment.type === 'PaymentType.Debit' ? payment.totalPayed : 0;
      const totalCheque =
        payment.type === 'PaymentType.Cheque' ? payment.totalPayed : 0;
      const refundSale = new Sale('', {
        cart: refundCartFiltered,
        payments: [payment],
        createdTime: Timestamp.now(),
        totalCash: totalCash,
        totalCredit: totalCredit,
        totalDebit: totalDebit,
        totalCheque: totalCheque,
        drawerName: drawerRef ? drawerRef.data().name : undefined,
        drawer: drawerRef ? drawerRef.ref : undefined,
        drawerId: drawerRef ? drawerRef.id : undefined,
        voided: false,
      });
      const collectionRepository = masterRepository.sale.collection(
        currentCompany!.key,
        currentStore!.key
      );
      const refundRef = doc(collectionRepository());
      batch.set(refundRef, refundSale);

      // Update returnQuantity
      const originalCart = _.cloneDeep(sale.cart);
      refundCart.cartItems.forEach((item, index) => {
        if (item.quantity > 0) {
          if (
            originalCart.cartItems[index].product.barcode !==
            item.product.barcode
          ) {
            throw new Error('Original cart and return cart does not match!');
          }
          (originalCart.cartItems[index].returnQuantity as number) +=
            item.quantity;
          if (
            originalCart.cartItems[index].returnQuantity >
            originalCart.cartItems[index].quantity
          ) {
            throw new Error('Return quantity is higher than quantity!');
          }
        }
      });

      // Update refunds mapping
      const saleRefFb = doc(
        db,
        `/companies/${currentCompany!.key}/stores/${currentStore!.key}/sales/${
          sale.key
        }`
      );
      batch.update(saleRefFb, {
        cart: originalCart.toFirestore(),
        refunds: arrayUnion(refundRef),
      });

      batch.commit();
      Messages.created();
      setVisible(false);
      history.push(`./${refundRef.id}`);
    } catch (error) {
      Notifications.error(error);
    } finally {
      setConfirmLoading(false);
    }
  };

  return (
    <>
      <Button
        hidden={sale.isVoidedOrRefund()}
        type='primary'
        onClick={() => setVisible(true)}
      >
        Refund
      </Button>
      <Modal
        title='Refund'
        centered
        visible={visible}
        onOk={() => processRefund()}
        okText={`Refund of ${ppPrice(payment.totalPayed)} in ${ppPaymentType(
          payment.type
        )}`}
        okButtonProps={{
          disabled: !refundCart.cartItems.some((item) => item.quantity > 0),
        }}
        confirmLoading={confirmLoading}
        onCancel={() => setVisible(false)}
      >
        <CartList
          cart={refundCart}
          setCart={setRefundCart}
          maxDiscount={0}
          viewOnly
          refund
        />
        <Row
          justify='space-between'
          style={{ paddingTop: 12, paddingBottom: 12 }}
        >
          <Text strong>Cash Drawer</Text>
          <Select
            value={drawerId}
            onChange={(value) => setDrawerId(value)}
            loading={isDrawerLoading}
          >
            <Select.Option value=''>Back office</Select.Option>
            {drawerSnapshots
              .map((snap) => snap.data())
              .map((drawer) => {
                return (
                  <Select.Option value={drawer.key}>
                    {drawer.name}
                  </Select.Option>
                );
              })}
          </Select>
        </Row>
        <Row
          justify='space-between'
          style={{ paddingTop: 12, paddingBottom: 12 }}
        >
          <Text strong>Type of Payment</Text>
          <Select
            value={paymentType}
            onChange={(value) => setPaymentType(value)}
          >
            <Select.Option value='PaymentType.Cash'>Cash</Select.Option>
            <Select.Option value='PaymentType.Credit'>Credit</Select.Option>
            <Select.Option value='PaymentType.Debit'>Debit</Select.Option>
            <Select.Option value='PaymentType.Cheque'>Cheque</Select.Option>
          </Select>
        </Row>
      </Modal>
    </>
  );
};

const Refund = ({ id }: { id: string }) => {
  return (
    <SaleProvider id={id}>
      <DrawerProvider
        where={{
          fieldPath: 'actualCash',
          opStr: '==',
          value: -1,
        }}
      >
        <RefundButton></RefundButton>
      </DrawerProvider>
    </SaleProvider>
  );
};

export { Refund };
