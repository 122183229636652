import {
  CreditCardOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  HistoryOutlined,
  MoneyCollectOutlined,
} from '@ant-design/icons/lib/icons';
import {
  Button,
  Col,
  Divider,
  Drawer,
  Dropdown,
  List,
  Popconfirm,
  Result,
  Row,
  Space,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import {
  addDoc,
  collection,
  doc,
  increment,
  serverTimestamp,
  Timestamp,
  updateDoc,
  writeBatch,
} from 'firebase/firestore';
import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
// @ts-ignore
import NumPad from 'react-numpad';
import { AuthContext } from '../../../firebase/Auth';
import { db } from '../../../firebase/Fb';
import { Cart } from '../../../models/cart';
import { Customer } from '../../../models/customer';
import { Drawer as CashDrawer } from '../../../models/drawer';
import { Payment, PaymentType } from '../../../models/payment';
import { Sale } from '../../../models/sale';
import { CustomerContext } from '../../../providers/base/CustomerProvider';
import { DrawerContext } from '../../../providers/base/DrawerProvider';
import { Repository } from '../../../providers/base/generics/GenericContextProvider';
import { SaleContext } from '../../../providers/base/SaleProvider';
import { BusySpin } from '../../../routes/Routes';
import { ppPrice, roundTo5Cents } from '../../../utils/Formatter';
import { Notifications } from '../../notification/Messages';
import { CartMenu } from './cart/CartMenu';
import { CartList } from './CartList';
import './ProductCart.scss';
import { Receipt } from './Receipt';

const totalAmountByType = (payments: Payment[], type: PaymentType) => {
  return payments
    .map((payment) => {
      if (payment.type === type) {
        return (
          payment.totalPayed -
          (payment.totalCashReturned > 0 ? payment.totalCashReturned : 0)
        );
      }
      return 0;
    })
    .reduce((prev, acc) => prev + acc, 0);
};

const createTransaction = async (
  saleRepository: Repository<Sale>,
  drawerRepository: Repository<CashDrawer>,
  customerRepository: Repository<Customer>,
  sale: Sale,
  payments: Payment[],
  drawerId: string,
  onSuccess: (transactionId: string) => void,
  onFailure: Function,
  demoUser: boolean
) => {
  // Skip for demo user.
  if (demoUser) {
    onSuccess('0');
    return;
  }

  try {
    const batch = writeBatch(db);
    const newSaleRef = doc(saleRepository!.collection());
    sale.key = newSaleRef.id;
    batch.set(newSaleRef, sale);

    // Loyalty points.
    if (sale.customer) {
      const pointRatio = 0.01; // 1 percent loyalty points. TODO: pull 0.01 from database somewhere.

      // Deduct used loyalty points.
      if (payments.some((pay) => pay.type === 'PaymentType.Loyalty')) {
        const totalLoyaltyDollars = payments.reduce((prev, curr) => {
          if (curr.type === 'PaymentType.Loyalty') {
            return prev + curr.totalPayed;
          }
          return prev;
        }, 0);

        const points = totalLoyaltyDollars * 100;
        const customerRef = doc(db, sale.customer);
        batch.update(customerRef, {
          points: increment(points * -1),
        });
      }

      // Add loyalty points.
      if (payments.some((pay) => pay.type !== 'PaymentType.Loyalty')) {
        const totalDollarsSpent = payments.reduce((prev, curr) => {
          if (curr.type !== 'PaymentType.Loyalty') {
            return prev + curr.totalPayed;
          }
          return prev;
        }, 0);

        const points = _.round(totalDollarsSpent * 100 * pointRatio);
        const customerRef = doc(db, sale.customer);
        batch.update(customerRef, {
          points: increment(points),
        });
      }

      // Subtract exempt loyalty ppoints. Discounted items or the Product is configured as Loyalty Exception.
      // TODO: this code is getting pretty bad. Must refactor this entire create transaction :/ but not enough time!!!
      const points = _.round(
        sale.cart.calculateLoyaltyExemption() * pointRatio
      );
      if (points > 0) {
        const customerRef = doc(db, sale.customer);
        batch.update(customerRef, {
          points: increment(points * -1),
        });
      }
    }

    // Update cash drawer.
    const totalCashAmount = totalAmountByType(payments, 'PaymentType.Cash');
    const totalCreditAmount = totalAmountByType(payments, 'PaymentType.Credit');
    const totalDebitAmount = totalAmountByType(payments, 'PaymentType.Debit');
    const totalChequeAmount = totalAmountByType(payments, 'PaymentType.Cheque');
    if (drawerId !== 'none') {
      batch.update(newSaleRef, {
        totalCash: totalCashAmount,
        totalCredit: totalCreditAmount,
        totalDebit: totalDebitAmount,
        totalCheque: totalChequeAmount,
      });
      batch.update(drawerRepository!.document(drawerId), {
        salesCash: increment(totalCashAmount),
        salesCredit: increment(totalCreditAmount),
        salesDebit: increment(totalDebitAmount),
        salesCheque: increment(totalChequeAmount),
      });
    }

    // Do not put await keyword here as we rely on firebase offline mode to persist data to firebase. This is because we don't want to prevent POS from not allowing transactions upon bad network connection.
    batch.commit();
    // Open cash drawer.
    onPrint('');
    onSuccess(newSaleRef.id);
  } catch (error) {
    Notifications.error(error);
    onFailure();
  }
};

const ProductCart = ({
  drawerId,
  cart,
  setCart,
  maxHeight,
  initCart,
}: {
  drawerId: string;
  cart: Cart;
  setCart: React.Dispatch<React.SetStateAction<Cart>>;
  maxHeight: string;
  initCart: Function;
}) => {
  const { currentCompany, currentStore, currentUser } = useContext(AuthContext);
  const { snapshots: drawerSnapshots, repository: drawerRepository } =
    useContext(DrawerContext);
  const { repository: customerRepository } = useContext(CustomerContext);
  const { repository: saleRepository } = useContext(SaleContext);
  const [prevCartSize, setPrevCartSize] = useState(0);
  const [transaction, setTransaction] = useState(false);
  const [oweAmount, setOweAmount] = useState(cart.calculateTotal());
  const [oweAmountRound, setOweAmountRound] = useState(
    roundTo5Cents(oweAmount)
  );
  const [paymentAmount, setPaymentAmount] = useState(cart.calculateTotal());
  const [payments, setPayments] = useState<Payment[]>([]);
  const [sale, setSale] = useState<Sale>();
  const [loading, setLoading] = useState(false);
  const [availableLoyaltyDollars, setAvailableLoyaltyDollars] = useState(10);
  const [customer, setCustomer] = useState<Customer>();
  const pageEndRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const drawerDoc = drawerSnapshots.find((snap) => snap.id === drawerId);
  const drawer = drawerDoc?.data();
  const [timer, setTimer] = useState({
    diff: 0,
    transactionId: '',
  });

  const resetCart = () => {
    initCart();
    setCustomer(undefined);
  };

  const createAuditLog = async (
    cart: Cart,
    by: string,
    companyId: string,
    storeId: string
  ) => {
    await addDoc(
      collection(db, 'companies', companyId, 'stores', storeId, 'auditlogs'),
      {
        cart: cart.toFirestore(),
        by: by ?? '',
        createdTime: serverTimestamp(),
      }
    );
  };

  // Use this to monitor transaction time.
  // useEffect(() => {
  //   try {
  //     if (timer.transactionId) {
  //       console.log(
  //         `Time it took to save transaction #${timer.transactionId} was ${timer.diff}`
  //       );
  //       addDoc(
  //         collection(db, `companies/${currentCompany!.key}/_performance_`),
  //         {
  //           ...timer,
  //           createdTime: serverTimestamp(),
  //         }
  //       ).catch((error) => {
  //         console.error(error);
  //       });
  //     }
  //   } catch (error) {
  //     // Swallow error. Not important for the user.
  //   }
  // }, [timer]);

  // useEffect(() => {
  //   if (
  //     sale &&
  //     payments.some(
  //       (payment) =>
  //         payment.type === 'PaymentType.Cash' || payment.totalCashReturned > 0
  //     )
  //   ) {
  //     onPrint();
  //   }
  // }, [sale]);

  useEffect(() => {
    if (drawerDoc) {
      updateDoc(drawerDoc.ref, {
        cart: cart.toFirestoreDisplayMode(),
        updatedTime: Timestamp.now(),
      });
    }
    // Ignore drawerDoc as this is going to be updated constantly when another person is watching cart display.
  }, [cart]);

  useEffect(() => {
    if (customer) {
      setAvailableLoyaltyDollars(_.round(customer.points / 100, 2));
    } else {
      setAvailableLoyaltyDollars(0);
    }
  }, [customer]);

  useEffect(() => {
    setOweAmountRound(roundTo5Cents(oweAmount));
  }, [oweAmount]);

  useEffect(() => {
    setPrevCartSize(cart.cartItems.length);
    setPaymentAmount(cart.calculateTotal());
    setOweAmount(cart.calculateTotal());
    setPayments([]);
    setSale(undefined);
    setTransaction(false);
    setLoading(false);

    if (cart.cartItems.length > prevCartSize) {
      const lastElement = document.getElementById('last-cart-item');
      lastElement?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [cart, pageEndRef]);

  useEffect(() => {
    if (transaction && payments.length > 0) {
      const total = cart.calculateTotal();
      const paidTotal = payments
        .map((payment) => payment.totalPayed)
        .reduce((prev, curr) => prev + curr, 0);
      const loyaltyPaidTotal = payments
        .map((payment) =>
          payment.type === 'PaymentType.Loyalty' ? payment.totalPayed : 0
        )
        .reduce((prev, curr) => prev + curr, 0);

      if (
        _.round(paidTotal, 2) >= parseFloat(total.toFixed(2)) && // Bug where using _.round(total, 2) causes 41.394999999999996 to round to 41.4 instead of 41.39
        !!cart.cartItems.length
      ) {
        const timerStart = moment();
        setLoading(true);
        const sale = new Sale('', {
          cart: cart,
          ...(drawerDoc && { drawer: drawerDoc.ref }),
          drawerId: drawerId,
          drawerName: drawer?.name ?? '',
          ...(customer && {
            customer: customerRepository?.document(customer.key)?.path,
          }),
          payments: payments,
          voided: false,
          createdTime: Timestamp.now(),
        });
        createTransaction(
          saleRepository!,
          drawerRepository!,
          customerRepository!,
          sale,
          payments,
          drawerId,
          (transactionId) => {
            const timerEnd = moment();
            setTimer({
              diff: timerEnd.diff(timerStart),
              transactionId: transactionId,
            });
            setSale(sale);
            setLoading(false);
            setPayments([]); // see if this fixes duplicate purchases bug
          },
          () => {
            setLoading(false);
          },
          currentUser.firestore?.demoUser ?? false
        );
      } else {
        const newOweAmount = _.round(total - paidTotal, 2);
        setOweAmount(newOweAmount);
        setPaymentAmount(newOweAmount);
        if (customer) {
          setAvailableLoyaltyDollars(
            _.round(customer.points / 100, 2) - loyaltyPaidTotal
          );
        }
      }
    }
  }, [payments, transaction]);

  const addPay = (payment: Payment) => {
    const newPayments = _.cloneDeep(payments);
    newPayments.push(payment);
    setPayments(newPayments);
  };

  const addQuickPaymentAmount = (number: number) => {
    if (paymentAmount === oweAmount) {
      setPaymentAmount(number);
    } else {
      setPaymentAmount(paymentAmount + number);
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: maxHeight,
          height: '100%',
        }}
      >
        <Row justify='space-between'>
          <Popconfirm
            title='Are you sure you want to clear this cart?'
            onConfirm={() => {
              createAuditLog(
                cart,
                drawer?.name ?? '',
                currentCompany!.key,
                currentStore!.key
              );
              resetCart();
            }}
            okText='Clear cart'
            okType='danger'
            cancelText='Cancel'
          >
            <Button icon={<DeleteOutlined />} />
          </Popconfirm>
          <Text strong style={{ margin: 'auto 0' }}>
            {drawer?.name ? drawer.name : drawer?.key ?? 'Cart'}
          </Text>
          <Dropdown
            overlay={CartMenu({
              cart: cart,
              setCart: setCart,
              drawer: drawer,
              setCustomer: setCustomer,
            })}
            trigger={['click']}
            placement='bottomRight'
          >
            <Button icon={<EllipsisOutlined />} />
          </Dropdown>
        </Row>
        <CartList
          cart={cart}
          setCart={setCart}
          maxDiscount={currentStore?.maxDiscount ?? 100}
        />
        {customer && (
          <List>
            <List.Item>
              <List.Item.Meta
                title={
                  customer.businessname
                    ? customer.businessname
                    : `${customer.firstname} ${customer.lastname}`
                }
                description={`${customer.points} points`}
              />
              <Button
                icon={<DeleteOutlined />}
                onClick={() => {
                  setCustomer(undefined);
                  setCart((prev) => {
                    const newCart = _.cloneDeep(prev);
                    (newCart.customerId as string) = '';
                    return newCart;
                  });
                }}
              />
            </List.Item>
          </List>
        )}
        <Row justify='center'>
          <Button
            style={{ height: 55 }}
            type='primary'
            size='large'
            block
            onClick={() => setTransaction(true)}
            disabled={!cart.cartItems.length}
          >
            <Row justify='space-between'>
              <span style={{ fontWeight: 'bold' }}>
                {cart.size()}
                <Divider style={{ height: '100%' }} type='vertical'></Divider>
              </span>
              <span style={{ fontWeight: 'bold' }}>
                Charge {ppPrice(cart.calculateTotal())}
              </span>
              <span></span>
            </Row>
          </Button>
        </Row>
      </div>
      <Drawer
        closable={!sale}
        keyboard={!sale}
        title='Payment'
        placement='bottom'
        visible={transaction}
        onClose={() => {
          setTransaction(false);
        }}
        height={'100%'}
        // footer={
        //   <Button block size='large' type='primary' onClick={onClickTest}>
        //     Print Receipt
        //   </Button>
        // }
      >
        {customer && (
          <Row justify='center'>
            <Space
              size='small'
              direction='vertical'
              align='center'
              style={{ rowGap: 0 }}
            >
              <Title level={3} style={{ marginBottom: 0 }}>
                For{' '}
                {customer.businessname
                  ? customer.businessname
                  : `${customer.firstname} ${customer.lastname}`}
              </Title>{' '}
              <Text>{customer.points} points</Text>
            </Space>
          </Row>
        )}
        {loading ? (
          <BusySpin></BusySpin>
        ) : !sale ? (
          <div
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              zoom: 1.25,
            }}
          >
            <Row wrap={false}>
              <Col flex='auto'>
                <Row justify='center'>
                  <Button
                    style={{ display: !!payments.length ? '' : 'none' }}
                    icon={<HistoryOutlined />}
                    type='text'
                    size='large'
                    onClick={() => {
                      setPayments([]);
                    }}
                  ></Button>
                </Row>
                <Row justify='space-around'>
                  <div>
                    {currentUser.firestore?.demoUser ? (
                      <Row>
                        <Title type='danger' level={2}>
                          DEMO TRANSACTION
                        </Title>
                      </Row>
                    ) : (
                      <></>
                    )}
                    <Row justify='center'>
                      <Title level={4} style={{ marginBottom: 0 }}>
                        Price
                      </Title>
                    </Row>
                    <Row justify='center'>
                      <Title>{ppPrice(oweAmount)}</Title>
                    </Row>
                  </div>
                  {/* <div>
                  <Row justify='center'>
                    <Title level={5} style={{ marginBottom: 0 }}>
                      Rounding
                    </Title>
                  </Row>
                  <Row justify='center'>
                    <Title>{ppPrice(oweAmountRound)}</Title>
                  </Row>
                </div> */}
                </Row>

                <Row justify='center' style={{ marginBottom: 16 }}>
                  <Row justify='space-between' style={{ width: '100%' }}>
                    <Title level={4} style={{ margin: 'auto 0' }}>
                      Amount
                    </Title>
                    <span id='numpad-text-area-right' className='drawer-numpad'>
                      <NumPad.Number
                        style={{ textAlign: 'right' }}
                        position='center'
                        onChange={(value: any) => {
                          setPaymentAmount(_.toNumber(value));
                        }}
                        placeholder={ppPrice(paymentAmount)}
                        decimal={2}
                        negative={false}
                      />
                    </span>
                  </Row>
                </Row>
                <Row justify='center'>
                  <Space
                    size='large'
                    style={{
                      display: paymentAmount > oweAmount ? '' : 'none',
                    }}
                  >
                    <Title level={5} style={{ marginBottom: 0 }}>
                      Change due
                    </Title>
                    <Title level={5} style={{ marginBottom: 0 }}>
                      {ppPrice(paymentAmount - oweAmount)}
                    </Title>
                  </Space>
                </Row>
                <Row
                  justify='center'
                  style={{ display: paymentAmount < oweAmount ? '' : 'none' }}
                >
                  <Text type='danger'>Insufficient amount</Text>
                </Row>
                <div style={{ marginTop: 48 }}></div>
                <Row justify='center'>
                  <Row
                    justify='space-between'
                    style={{ width: 400 }}
                    wrap={false}
                  >
                    <Button
                      block
                      type='primary'
                      size='large'
                      style={{
                        marginRight: 8,
                        visibility: availableLoyaltyDollars
                          ? 'visible'
                          : 'hidden',
                      }}
                      icon={<CreditCardOutlined />}
                      disabled={
                        paymentAmount > oweAmount ||
                        availableLoyaltyDollars < paymentAmount
                      }
                      onClick={() => {
                        const pay = new Payment({
                          type: 'PaymentType.Loyalty',
                          totalPayed: paymentAmount,
                          totalCashReturned: paymentAmount - oweAmount,
                        });
                        addPay(pay);
                      }}
                    >
                      Loyalty{' '}
                      {availableLoyaltyDollars < paymentAmount
                        ? `${ppPrice(availableLoyaltyDollars)}`
                        : ppPrice(paymentAmount)}
                    </Button>
                    <Button
                      block
                      type='primary'
                      size='large'
                      style={{ marginLeft: 8 }}
                      icon={<CreditCardOutlined />}
                      disabled={paymentAmount > oweAmount}
                      onClick={() => {
                        const pay = new Payment({
                          type: 'PaymentType.Cheque',
                          totalPayed: paymentAmount,
                          totalCashReturned: paymentAmount - oweAmount,
                        });
                        addPay(pay);
                      }}
                    >
                      Cheque {ppPrice(paymentAmount)}
                    </Button>
                  </Row>
                </Row>
                <Row justify='center' style={{ marginTop: 24 }}>
                  <Row
                    justify='space-between'
                    style={{ width: 400 }}
                    wrap={false}
                  >
                    <Button
                      block
                      type='primary'
                      size='large'
                      style={{ marginRight: 8 }}
                      icon={<CreditCardOutlined />}
                      disabled={paymentAmount > oweAmount}
                      onClick={() => {
                        const pay = new Payment({
                          type: 'PaymentType.Debit',
                          totalPayed: paymentAmount,
                          totalCashReturned: paymentAmount - oweAmount,
                        });
                        addPay(pay);
                      }}
                    >
                      Debit {ppPrice(paymentAmount)}
                    </Button>
                    <Button
                      block
                      type='primary'
                      size='large'
                      style={{ marginLeft: 8 }}
                      icon={<CreditCardOutlined />}
                      disabled={paymentAmount > oweAmount}
                      onClick={() => {
                        const pay = new Payment({
                          type: 'PaymentType.Credit',
                          totalPayed: paymentAmount,
                          totalCashReturned: paymentAmount - oweAmount,
                        });
                        addPay(pay);
                      }}
                    >
                      Credit {ppPrice(paymentAmount)}
                    </Button>
                  </Row>
                </Row>
                <Row justify='center' style={{ marginTop: 24 }}>
                  <div style={{ width: 400 }}>
                    <Button
                      block
                      type='primary'
                      size='large'
                      icon={<MoneyCollectOutlined />}
                      onClick={() => {
                        const pay = new Payment({
                          type: 'PaymentType.Cash',
                          totalPayed: paymentAmount,
                          totalCashReturned: paymentAmount - oweAmount,
                        });
                        addPay(pay);
                      }}
                    >
                      Cash {ppPrice(paymentAmount)}
                    </Button>
                  </div>
                </Row>
              </Col>
              <Col>
                <Divider
                  type='vertical'
                  style={{ height: '100%', marginLeft: 50, marginRight: 50 }}
                />
              </Col>
              <Col>
                <Space
                  size='large'
                  direction='vertical'
                  style={{ width: '100px' }}
                >
                  <Button
                    type='primary'
                    block
                    size='large'
                    onClick={() => addQuickPaymentAmount(5)}
                  >
                    $5
                  </Button>
                  <Button
                    type='primary'
                    block
                    size='large'
                    onClick={() => addQuickPaymentAmount(10)}
                  >
                    $10
                  </Button>
                  <Button
                    type='primary'
                    block
                    size='large'
                    onClick={() => addQuickPaymentAmount(20)}
                  >
                    $20
                  </Button>
                  <Button
                    type='primary'
                    block
                    size='large'
                    onClick={() => addQuickPaymentAmount(50)}
                  >
                    $50
                  </Button>
                  <Button
                    type='primary'
                    block
                    size='large'
                    onClick={() => addQuickPaymentAmount(100)}
                  >
                    $100
                  </Button>
                  <Button
                    type='primary'
                    block
                    size='large'
                    onClick={() => setPaymentAmount(0)}
                  >
                    Clear
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>
        ) : (
          <LastTransactionSummary resetCart={resetCart} sale={sale} />
        )}
      </Drawer>
    </>
  );
};

const LastTransactionSummary = ({
  resetCart,
  sale,
}: {
  resetCart: Function;
  sale: Sale;
}) => {
  const { currentStore } = useContext(AuthContext);

  if (!sale || !currentStore) return <Text>Transaction not found.</Text>;
  return (
    <div
      key={'transaction-summary' + sale.key}
      style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 500,
      }}
    >
      <Result
        style={{ width: '100%' }}
        key={'result' + sale.key + sale.cart.calculateTotal()}
        status='success'
        title={
          sale.payments.slice(-1)[0]?.totalCashReturned ?? 0 > 0
            ? `Change ${ppPrice(sale.payments.slice(-1)[0].totalCashReturned)}`
            : 'Transaction complete!'
        }
        subTitle={`Sales number: ${sale.getTransactionTimestampId()}`}
        extra={[
          <Button
            style={{
              height: 70,
              padding: '6.4px 24px',
            }}
            size='large'
            type='primary'
            onClick={() => {
              const content =
                document.getElementById('print-content')?.innerHTML ?? '';
              onPrint(content);
            }}
          >
            Print receipt
          </Button>,
          <Button
            style={{
              height: 70,
              padding: '6.4px 24px',
            }}
            size='large'
            onClick={() => onPrint('')}
          >
            Open cash drawer
          </Button>,
          <Button
            size='large'
            type='primary'
            key='console'
            onClick={() => resetCart()}
            style={{
              height: 70,
              padding: '6.4px 24px',
            }}
          >
            Complete
          </Button>,
        ]}
      />
      <Receipt key={'receipt-' + sale.key} store={currentStore} sale={sale} />
    </div>
  );
};

const onPrint = (content: string) => {
  const printHtml = `<html>
        <head>
            <meta charset="utf-8">
            <title>Name Card</title>
        </head>
        <style>
            @media print {
                @page {
                    size: 79mm auto;
                    margin: 0;
                }
            }
            body {
              font-family: Arial, Helvetica, sans-serif;
            }
        </style>
        <body>
            ${content}
        </body>
    </html>`;

  // get the iframe
  let iFrame = document.getElementById('print-iframe') as any;

  // set the iFrame contents and print
  if (iFrame) {
    iFrame.contentDocument.body.innerHTML = printHtml;
    iFrame.focus();
    iFrame.contentWindow.print();
  }
};

export { ProductCart, onPrint, totalAmountByType };
