import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, List, Modal, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import { doc } from 'firebase/firestore';
import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { db } from '../../../firebase/Fb';
import { Cart } from '../../../models/cart';
import { DepartmentContext } from '../../../providers/base/DepartmentProvider';
import { FeeContext } from '../../../providers/base/FeeProvider';
import { GroupContext } from '../../../providers/base/GroupProvider';
import { ProductContext } from '../../../providers/base/ProductProvider';
import { BusySpin } from '../../../routes/Routes';
import { ppPrice } from '../../../utils/Formatter';
import { BarcodeInput } from './BarcodeInput';
import './ProductSelector.scss';
import { VariablePriceDialog } from './VariablePriceDialog';

interface Tile {
  key: string;
  title: string;
  colorClass: string;
  onClick: React.MouseEventHandler<HTMLElement> | undefined;
  price?: number;
  variable?: boolean;
  negative?: boolean;
}

const ProductSelector = ({
  setCart,
  maxHeight,
}: {
  setCart: React.Dispatch<React.SetStateAction<Cart>>;
  maxHeight: string;
}) => {
  const { snapshots: productSnapshots, isLoading: isProductLoading } =
    useContext(ProductContext);
  const { snapshots: groupSnapshots, isLoading: isGroupLoading } =
    useContext(GroupContext);
  const { snapshots: departmentSnapshots, isLoading: isDepartmentLoading } =
    useContext(DepartmentContext);
  const { snapshots: feeSnapshots, isLoading: isFeeLoading } =
    useContext(FeeContext);
  const isLoading =
    isProductLoading || isGroupLoading || isDepartmentLoading || isFeeLoading;
  const history = useHistory();

  if (isLoading) {
    return <BusySpin />;
  }

  let tiles: Tile[] = [
    ...departmentSnapshots
      .filter((snap) => snap.data().favourite)
      .sort((a, b) => a.data().title.localeCompare(b.data().title))
      .map((snap) => {
        const department = snap.data();
        const productList = productSnapshots
          .filter(
            (pSnap) =>
              department.products.includes(pSnap.ref.path) &&
              !pSnap.data().disabled
          )
          .sort((a, b) => a.data().title.localeCompare(b.data().title))
          .map((pSnap) => {
            const product = pSnap.data();
            const groups = _.compact(
              product.groups.map((path) =>
                groupSnapshots.find((snap) => snap.ref.path === path)?.data()
              )
            );
            const fees = _.compact(
              product.fees.map((path) =>
                feeSnapshots.find((snap) => snap.ref.path === path)?.data()
              )
            );
            const departments = departmentSnapshots
              .filter((snap) =>
                snap.data().products.some((path) => path === pSnap.ref.path)
              )
              .map((snap) => snap.data());
            return {
              key: product.key,
              title: product.title,
              price: product.price,
              variable: product.variable,
              description: product.description,
              onClick: product.variable
                ? () =>
                    VariablePriceDialog((price) =>
                      setCart((prevCart) => {
                        const newCart = _.cloneDeep(prevCart);
                        const newProduct = _.cloneDeep(product);
                        (newProduct.price as number) = product.negative
                          ? price * -1
                          : price;
                        newCart.add({
                          product: newProduct,
                          departments: departments,
                          groups: groups,
                          fees: fees,
                          discounts: [],
                          quantity: 1,
                        });
                        return newCart;
                      })
                    )
                : () => {
                    setCart((prevCart) => {
                      const newCart = _.cloneDeep(prevCart);
                      const customerSpecificPrice = product.prices.find(
                        (customerPrice) => {
                          const customerRef = doc(db, customerPrice.customer);
                          return customerRef.id === prevCart.customerId;
                        }
                      );
                      if (customerSpecificPrice) {
                        (product.price as number) = customerSpecificPrice.price;
                      }
                      newCart.add({
                        product: product,
                        departments: departments,
                        groups: groups,
                        fees: fees,
                        discounts: [],
                        quantity: 1,
                      });
                      return newCart;
                    });
                  },
            };
          });
        return {
          key: department.key,
          title: truncateString(department.title),
          colorClass: 'ant-tag-purple',
          onClick: () => {
            const Dialog = Modal.success({
              centered: true,
              closable: true,
              maskClosable: true,
              title: department.title,
              okText: null,
              icon: null,
              content: (
                <List
                  dataSource={productList}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        title={item.title}
                        description={item.description}
                      />
                      <Button
                        type='primary'
                        onClick={() => {
                          item.onClick();
                          Dialog.destroy();
                        }}
                      >
                        {item.variable ? 'Variable' : ppPrice(item.price)}
                      </Button>
                    </List.Item>
                  )}
                />
              ),
              okButtonProps: {
                style: {
                  display: 'none',
                },
              },
            });
          },
        };
      }),
    ...groupSnapshots
      .filter((snap) => snap.data().favourite)
      .sort((a, b) => a.data().title.localeCompare(b.data().title))
      .map((snap) => {
        const group = snap.data();
        const productList = productSnapshots
          .filter(
            (pSnap) =>
              pSnap.data().groups.includes(snap.ref.path) &&
              !pSnap.data().disabled
          )
          .sort((a, b) => a.data().title.localeCompare(b.data().title))
          .map((pSnap) => {
            const product = pSnap.data();
            const groups = _.compact(
              product.groups.map((path) =>
                groupSnapshots.find((snap) => snap.ref.path === path)?.data()
              )
            );
            const fees = _.compact(
              product.fees.map((path) =>
                feeSnapshots.find((snap) => snap.ref.path === path)?.data()
              )
            );
            const departments = departmentSnapshots
              .filter((snap) =>
                snap.data().products.some((path) => path === pSnap.ref.path)
              )
              .map((snap) => snap.data());
            return {
              key: product.key,
              title: product.title,
              price: product.price,
              description: product.description,
              onClick: product.variable
                ? () =>
                    VariablePriceDialog((price) =>
                      setCart((prevCart) => {
                        const newCart = _.cloneDeep(prevCart);
                        const newProduct = _.cloneDeep(product);
                        (newProduct.price as number) = product.negative
                          ? price * -1
                          : price;
                        newCart.add({
                          product: newProduct,
                          departments: departments,
                          groups: groups,
                          fees: fees,
                          discounts: [],
                          quantity: 1,
                        });
                        return newCart;
                      })
                    )
                : () => {
                    setCart((prevCart) => {
                      const newCart = _.cloneDeep(prevCart);
                      const customerSpecificPrice = product.prices.find(
                        (customerPrice) => {
                          const customerRef = doc(db, customerPrice.customer);
                          return customerRef.id === prevCart.customerId;
                        }
                      );
                      if (customerSpecificPrice) {
                        (product.price as number) = customerSpecificPrice.price;
                      }
                      newCart.add({
                        product: product,
                        departments: departments,
                        groups: groups,
                        fees: fees,
                        discounts: [],
                        quantity: 1,
                      });
                      return newCart;
                    });
                  },
            };
          });
        return {
          key: group.key,
          title: truncateString(group.title),
          colorClass: 'ant-tag-green',
          onClick: () => {
            const Dialog = Modal.success({
              centered: true,
              closable: true,
              maskClosable: true,
              title: group.title,
              okText: null,
              icon: null,
              content: (
                <List
                  dataSource={productList}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        title={item.title}
                        description={item.description}
                      />
                      <Button
                        type='primary'
                        onClick={() => {
                          item.onClick();
                          Dialog.destroy();
                        }}
                      >
                        {ppPrice(item.price)}
                      </Button>
                    </List.Item>
                  )}
                />
              ),
              okButtonProps: {
                style: {
                  display: 'none',
                },
              },
            });
          },
        };
      }),
  ];

  tiles = [
    ...tiles,
    ...productSnapshots
      .filter((snap) => snap.data().favourite && !snap.data().disabled)
      .sort((a, b) => a.data().title.localeCompare(b.data().title))
      .map((pSnap) => {
        const product = pSnap.data();
        const groups = _.compact(
          product.groups.map((path) =>
            groupSnapshots.find((snap) => snap.ref.path === path)?.data()
          )
        );
        const fees = _.compact(
          product.fees.map((path) =>
            feeSnapshots.find((snap) => snap.ref.path === path)?.data()
          )
        );
        const departments = departmentSnapshots
          .filter((snap) =>
            snap.data().products.some((path) => path === pSnap.ref.path)
          )
          .map((snap) => snap.data());
        return {
          key: product.key,
          title: truncateString(product.title),
          colorClass: product.favouriteColor
            ? `ant-tag-${product.favouriteColor}`
            : 'ant-tag-blue',
          price: product.price,
          variable: product.variable,
          negative: product.negative,
          onClick: product.variable
            ? () =>
                VariablePriceDialog((price) =>
                  setCart((prevCart) => {
                    const newCart = _.cloneDeep(prevCart);
                    const newProduct = _.cloneDeep(product);
                    (newProduct.price as number) = product.negative
                      ? price * -1
                      : price;
                    newCart.add({
                      product: newProduct,
                      departments: departments,
                      groups: groups,
                      fees: fees,
                      discounts: [],
                      quantity: 1,
                    });
                    return newCart;
                  })
                )
            : () => {
                setCart((prevCart) => {
                  const newCart = _.cloneDeep(prevCart);
                  const customerSpecificPrice = product.prices.find(
                    (customerPrice) => {
                      const customerRef = doc(db, customerPrice.customer);
                      return customerRef.id === prevCart.customerId;
                    }
                  );
                  if (customerSpecificPrice) {
                    (product.price as number) = customerSpecificPrice.price;
                  }
                  newCart.add({
                    product: product,
                    departments: departments,
                    groups: groups,
                    fees: fees,
                    discounts: [],
                    quantity: 1,
                  });
                  return newCart;
                });
              },
        };
      }),
  ];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        maxHeight: maxHeight,
      }}
    >
      <Row justify='space-between' style={{ marginBottom: 16 }}>
        <Button
          icon={<ArrowLeftOutlined />}
          onClick={() => history.push('.')}
        />
        <LegalAge />
        <span></span>
      </Row>
      <BarcodeInput setCart={setCart} />
      <Row gutter={[16, 16]} style={{ marginTop: 16, overflow: 'auto' }}>
        {tiles.map((tile) => {
          return (
            <Col span={6} key={tile.key}>
              <Button
                size='large'
                block
                style={{
                  height: '100px',
                  whiteSpace: 'normal',
                }}
                className={tile.colorClass}
                onClick={tile.onClick}
              >
                {/* <Space
                  size='small'
                  style={{ width: '100%' }}
                  direction='vertical'
                >
                  <div
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {tile.title}
                  </div>
                  {tile.variable
                    ? tile.negative
                      ? '-$var'
                      : '$var'
                    : tile.price !== undefined && (
                        <div>{ppPrice(tile.price)}</div>
                      )}
                </Space> */}
                <div className='product-tile-title'>{tile.title}</div>
              </Button>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

const LegalAge = () => {
  const [dob, setDob] = useState(
    moment().subtract(18, 'year').format('yyyy-MM-DD')
  );

  useEffect(() => {
    const interval = setInterval(
      () => setDob(moment().subtract(18, 'year').format('yyyy-MM-DD')),
      1000 * 60 * 60
    );

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <Text strong>Legal Age DOB {dob}</Text>;
};

const truncateString = (str: string) => {
  return str.substring(0, 30);
};

export { ProductSelector };
