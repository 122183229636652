import { CheckCircleOutlined, StopOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  List,
  Modal,
  PageHeader,
  Popconfirm,
  Select,
  Space,
  Tag,
  Typography,
} from 'antd';
import { doc, increment, runTransaction, writeBatch } from 'firebase/firestore';
import _ from 'lodash';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../../firebase/Auth';
import { masterRepository } from '../../../../firebase/db';
import { db } from '../../../../firebase/Fb';
import { Drawer } from '../../../../models/drawer';
import { PaymentType } from '../../../../models/payment';
import { Sale } from '../../../../models/sale';
import { SaleContext } from '../../../../providers/pagination/SaleProvider';
import { BusySpin } from '../../../../routes/Routes';
import { ppPaymentType, ppPrice } from '../../../../utils/Formatter';
import { CardStyle } from '../../../common/detail/cards/card-styles';
import { DetailLayout } from '../../../common/detail/DetailLayout';
import { NotFound } from '../../../common/result/NotFound';
import { Messages, Notifications } from '../../../notification/Messages';
import { CartList } from '../../register/CartList';
import { onPrint, totalAmountByType } from '../../register/ProductCart';
import { Receipt } from '../../register/Receipt';
import { Refund } from '../RefundButton';
const { Text } = Typography;

const paymentTypeToDrawerColumn = (type: PaymentType) => {
  let drawerColumn:
    | 'salesCash'
    | 'salesCredit'
    | 'salesDebit'
    | 'salesCheque'
    | undefined;
  switch (type) {
    case 'PaymentType.Cash':
      drawerColumn = 'salesCash';
      break;
    case 'PaymentType.Debit':
      drawerColumn = 'salesDebit';
      break;
    case 'PaymentType.Credit':
      drawerColumn = 'salesCredit';
      break;
    case 'PaymentType.Cheque':
      drawerColumn = 'salesCheque';
      break;

    default:
      break;
  }
  return drawerColumn;
};

const TransactionDetail = () => {
  const { snapshot, isLoading } = useContext(SaleContext);

  if (isLoading) {
    return <BusySpin />;
  } else if (!snapshot?.exists()) {
    return (
      <NotFound
        backUrl='.'
        backButtonText='View transactions'
        itemText='transaction'
      />
    );
  } else {
    return <Detail />;
  }
};

const Detail = () => {
  const history = useHistory();
  const { snapshot } = useContext(SaleContext);
  const { currentCompany, currentStore } = useContext(AuthContext);
  const [voidLoading, setVoidLoading] = useState(false);
  const sale = snapshot?.data()!;
  const voidText = sale.voided ? 'Enable' : 'Void';

  // Think about what to do with repository.
  const repository = masterRepository.sale.document(
    currentCompany!.key,
    currentStore!.key
  );

  return (
    <DetailLayout
      pageHeader={
        <PageHeader
          onBack={() => history.push('.')}
          title='Transaction'
          subTitle={`#${sale.getTransactionTimestampId()}`}
          tags={
            sale.isRefund() ? (
              <Tag icon={<CheckCircleOutlined />} color='success'>
                Refund
              </Tag>
            ) : sale.voided ? (
              <Tag icon={<StopOutlined />} color='error'>
                Void
              </Tag>
            ) : (
              <Tag icon={<CheckCircleOutlined />} color='success'>
                Sold
              </Tag>
            )
          }
          extra={
            <Space>
              <Popconfirm
                title={`Are you sure you want to ${voidText} this transaction?`}
                onConfirm={async () => {
                  try {
                    setVoidLoading(true);
                    await runTransaction(db, async (transaction) => {
                      // Update drawer if it's open.
                      if (sale.drawer) {
                        const drawerDoc = await transaction.get(
                          doc(db, sale.drawer)
                        );
                        if (drawerDoc.exists()) {
                          const drawer = new Drawer(
                            drawerDoc.ref.id,
                            drawerDoc.data()
                          );
                          if (drawer.actualCash === -1) {
                            sale.payments.forEach((payment) => {
                              const totalCashPayed =
                                payment.totalPayed -
                                (payment.totalCashReturned > 0
                                  ? payment.totalCashReturned
                                  : 0);
                              const drawerPaymentTypeColumn =
                                paymentTypeToDrawerColumn(payment.type);
                              if (
                                drawerPaymentTypeColumn &&
                                (payment.type === 'PaymentType.Cash' ||
                                  payment.type === 'PaymentType.Cheque' ||
                                  payment.type === 'PaymentType.Debit' ||
                                  payment.type === 'PaymentType.Credit')
                              ) {
                                if (sale.voided) {
                                  // From void -> back to active
                                  transaction.update(drawerDoc.ref, {
                                    [drawerPaymentTypeColumn]:
                                      increment(totalCashPayed),
                                  });
                                } else {
                                  // Voiding transaction
                                  transaction.update(drawerDoc.ref, {
                                    [drawerPaymentTypeColumn]: increment(
                                      totalCashPayed * -1
                                    ),
                                  });
                                }
                              }
                            });
                          }
                        }
                      }

                      // Update sale.
                      transaction.update(repository(sale.key), {
                        voided: !sale.voided,
                      });

                      // Todo: go through all products and update inventory.
                    });

                    Messages.saved();
                  } catch (error) {
                    Notifications.error(error);
                  } finally {
                    setVoidLoading(false);
                  }
                }}
                okType='danger'
                okText={`Yes, ${voidText} this transaction`}
              >
                <Button
                  hidden={sale.hasRefund() || sale.isVoidedOrRefund()} // Not supported due to quantity and total cost adjustment on cloud functions.
                  type='primary'
                  danger={!sale.voided}
                  loading={voidLoading}
                >
                  {`${voidText} Transaction`}
                </Button>
              </Popconfirm>
              <Refund id={sale.key} />
              <Button
                onClick={() => {
                  const content =
                    document.getElementById('print-content')?.innerHTML ?? '';
                  onPrint(content);
                }}
              >
                Print Receipt
              </Button>
            </Space>
          }
        />
      }
      mainContent={
        <Card style={CardStyle}>
          <Space direction='vertical' style={{ width: '100%' }}>
            <Text strong>Sold Items</Text>
            <CartList
              cart={sale.cart}
              setCart={() => {}}
              maxDiscount={0}
              viewOnly
            />
          </Space>
        </Card>
      }
      sideContent={
        <>
          <Card style={CardStyle}>
            <Space direction='vertical' style={{ width: '100%' }}>
              <Text strong>Payment Methods</Text>
              <List>
                {sale.payments.map((payment, index) => {
                  const payedAmount =
                    payment.totalPayed -
                    (payment.totalCashReturned > 0
                      ? payment.totalCashReturned
                      : 0);
                  return (
                    <List.Item key={`payment-${index}`}>
                      <List.Item.Meta
                        title={ppPaymentType(payment.type)}
                        description={ppPrice(payedAmount)}
                      />
                      <Select
                        value={payment.type}
                        onChange={(value: PaymentType) => {
                          Modal.confirm({
                            title:
                              'Are you sure you want to change Payment Type?',
                            content: `Changing from ${ppPaymentType(
                              payment.type
                            )} to ${ppPaymentType(
                              value
                            )} with a value of ${ppPrice(payedAmount)}`,
                            centered: true,
                            onOk: async () => {
                              try {
                                // Using reference to edit.
                                const beforePaymentType = _.clone(payment.type);
                                payment.type = value;

                                const totalCashAmount = totalAmountByType(
                                  sale.payments,
                                  'PaymentType.Cash'
                                );
                                const totalCreditAmount = totalAmountByType(
                                  sale.payments,
                                  'PaymentType.Credit'
                                );
                                const totalDebitAmount = totalAmountByType(
                                  sale.payments,
                                  'PaymentType.Debit'
                                );
                                const totalChequeAmount = totalAmountByType(
                                  sale.payments,
                                  'PaymentType.Cheque'
                                );

                                const batch = writeBatch(db);
                                batch.set(
                                  repository(sale.key),
                                  new Sale('', {
                                    payments: sale.payments,
                                    totalCash: totalCashAmount,
                                    totalDebit: totalDebitAmount,
                                    totalCredit: totalCreditAmount,
                                    totalCheque: totalChequeAmount,
                                  }),
                                  {
                                    merge: true,
                                  }
                                );

                                // If drawer existed for this sale, make sure to update drawer sale value.
                                if (sale.drawer) {
                                  const drawerDoc = doc(db, sale.drawer);
                                  const amount =
                                    payment.totalPayed -
                                    (payment.totalCashReturned > 0
                                      ? payment.totalCashReturned
                                      : 0);
                                  const beforePaymentTypeColumn =
                                    paymentTypeToDrawerColumn(
                                      beforePaymentType
                                    );
                                  const afterPaymentTypeColumn =
                                    paymentTypeToDrawerColumn(payment.type);

                                  if (
                                    beforePaymentTypeColumn !==
                                    afterPaymentTypeColumn
                                  ) {
                                    // Subtract amount from previous payment in Register.
                                    if (beforePaymentTypeColumn) {
                                      batch.update(drawerDoc, {
                                        [beforePaymentTypeColumn]: increment(
                                          amount * -1
                                        ),
                                      });
                                    }

                                    // Add amount to new payment in Register.
                                    if (afterPaymentTypeColumn) {
                                      batch.update(drawerDoc, {
                                        [afterPaymentTypeColumn]:
                                          increment(amount),
                                      });
                                    }
                                  }
                                }

                                await batch.commit();
                                Messages.saved();
                              } catch (error) {
                                Notifications.error(error);
                              }
                            },
                            okText: `Yes, change to ${ppPaymentType(value)}`,
                          });
                        }}
                      >
                        <Select.Option value='PaymentType.Cash'>
                          Cash
                        </Select.Option>
                        <Select.Option value='PaymentType.Credit'>
                          Credit
                        </Select.Option>
                        <Select.Option value='PaymentType.Debit'>
                          Debit
                        </Select.Option>
                        <Select.Option value='PaymentType.Cheque'>
                          Cheque
                        </Select.Option>
                      </Select>
                    </List.Item>
                  );
                })}
              </List>
            </Space>
          </Card>
          <Card style={CardStyle}>
            <Receipt
              key={'receipt-' + sale.key}
              store={currentStore!}
              sale={sale}
              fullHeight={true}
            ></Receipt>
          </Card>
        </>
      }
      footerContent={
        <iframe
          style={{ display: 'none' }}
          id='print-iframe'
          width='100%'
          height='100%'
        />
      }
    />
  );
};

export { TransactionDetail };
