import { Badge, Card, Descriptions, Divider, List, Row, Spin } from 'antd';
import Meta from 'antd/lib/card/Meta';
import {
  collection,
  getAggregateFromServer,
  query,
  sum,
  where,
} from 'firebase/firestore';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../firebase/Auth';
import { db } from '../../../../firebase/Fb';
import { Drawer } from '../../../../models/drawer';
import { ppMomentDateTime, ppPrice } from '../../../../utils/Formatter';

const DrawerTile = ({
  drawer,
  history,
  url,
}: {
  drawer: Drawer;
  history: any;
  url: string;
}) => {
  const { currentCompany, currentStore } = useContext(AuthContext);
  const [loading, isLoading] = useState(false);
  const [totalCash, setTotalCash] = useState(0);
  const [totalCheque, setTotalCheque] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);
  const [totalDebit, setTotalDebit] = useState(0);

  useEffect(() => {
    const fetchDrawerData = async () => {
      isLoading(true);
      try {
        const q = query(
          collection(
            db,
            `companies/${currentCompany!.key}/stores/${currentStore!.key}/sales`
          ),
          where('drawerId', '==', drawer.key),
          where('voided', '==', false)
        );

        const snapshot = await getAggregateFromServer(q, {
          totalCash: sum('totalCash'),
          totalCheque: sum('totalCheque'),
          totalCredit: sum('totalCredit'),
          totalDebit: sum('totalDebit'),
        });

        setTotalCash(snapshot.data().totalCash);
        setTotalCheque(snapshot.data().totalCheque);
        setTotalCredit(snapshot.data().totalCredit);
        setTotalDebit(snapshot.data().totalDebit);
      } finally {
        isLoading(false);
      }
    };

    fetchDrawerData();
  }, []);

  return (
    <List.Item>
      <Card hoverable onClick={() => history.push(`${url}/${drawer.key}`)}>
        <Meta
          title={
            <Row justify='space-between'>
              <span>{drawer.name ? drawer.name : drawer.key}</span>
              <Badge status='processing' text='Open'></Badge>
            </Row>
          }
        />
        <Divider />
        <Descriptions bordered size='small'>
          <Descriptions.Item label='Start of Drawer' span={3}>
            {ppMomentDateTime(
              //@ts-ignore
              new moment(drawer?.createdTime?.toDate()),
              'ddd, MMM D, YYYY, h:mm:ss a'
            )}
          </Descriptions.Item>
          <Descriptions.Item label='Start Cash' span={3}>
            {ppPrice(drawer.initialCash)}
          </Descriptions.Item>
          <Descriptions.Item label='Cash In/Out' span={3}>
            {ppPrice(drawer.transferCash)}
          </Descriptions.Item>
          <Descriptions.Item label='Cash Sales' span={3}>
            {loading ? <Spin /> : ppPrice(totalCash)}
          </Descriptions.Item>
          <Descriptions.Item label='Debit Sales' span={3}>
            {loading ? <Spin /> : ppPrice(totalDebit)}
          </Descriptions.Item>
          <Descriptions.Item label='Credit Sales' span={3}>
            {loading ? <Spin /> : ppPrice(totalCredit)}
          </Descriptions.Item>
          <Descriptions.Item label='Cheque Sales' span={3}>
            {loading ? <Spin /> : ppPrice(totalCheque)}
          </Descriptions.Item>
          <Descriptions.Item label='Expected in Drawer' span={3}>
            {loading ? (
              <Spin />
            ) : (
              ppPrice(drawer.initialCash + drawer.transferCash + totalCash)
            )}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </List.Item>
  );
};

export { DrawerTile };
