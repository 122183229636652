import { Button, Input, InputNumber, List, Modal, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import {
  collection,
  getAggregateFromServer,
  query,
  runTransaction,
  sum,
  where,
} from 'firebase/firestore';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { db } from '../../../../firebase/Fb';
import { Drawer } from '../../../../models/drawer';
import { Repository } from '../../../../providers/base/generics/GenericContextProvider';
import { ppMomentDateTime, ppPrice } from '../../../../utils/Formatter';
import { DollarInputNumberFormatter } from '../../../common/detail/cards/card-styles';
import { Notifications } from '../../../notification/Messages';

const CloseDrawer = ({
  drawer,
  drawerRepository,
  history,
  companyId,
  storeId,
}: {
  drawer?: Drawer;
  drawerRepository: Repository<Drawer>;
  history: any;
  companyId: string;
  storeId: string;
}) => {
  const [actualAmount, setActualAmount] = useState<number>();
  const [reason, setReason] = useState('');
  const [loading, isLoading] = useState(false);
  const [totalCash, setTotalCash] = useState(0);
  const [totalCheque, setTotalCheque] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);
  const [totalDebit, setTotalDebit] = useState(0);

  useEffect(() => {
    const fetchDrawerData = async () => {
      isLoading(true);
      try {
        const q = query(
          collection(db, `companies/${companyId}/stores/${storeId}/sales`),
          where('drawerId', '==', drawer!.key),
          where('voided', '==', false)
        );

        const snapshot = await getAggregateFromServer(q, {
          totalCash: sum('totalCash'),
          totalCheque: sum('totalCheque'),
          totalCredit: sum('totalCredit'),
          totalDebit: sum('totalDebit'),
        });

        setTotalCash(snapshot.data().totalCash);
        setTotalCheque(snapshot.data().totalCheque);
        setTotalCredit(snapshot.data().totalCredit);
        setTotalDebit(snapshot.data().totalDebit);
      } finally {
        isLoading(false);
      }
    };
    fetchDrawerData();
  }, []);

  if (loading) return <Spin />;

  if (!drawer) return <Text>Unable to find drawer.</Text>;

  const expectedCash = drawer.initialCash + drawer.transferCash + totalCash;
  const shortCash = _.round(expectedCash - (actualAmount ?? 0), 2);

  return (
    <>
      <List>
        <List.Item>
          <List.Item.Meta
            title='Start of Drawer'
            description={ppMomentDateTime(
              // @ts-ignore
              new moment(drawer?.createdTime?.toDate()),
              'ddd, MMM D, YYYY, h:mm:ss a'
            )}
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta
            title='Starting cash'
            description={ppPrice(drawer.initialCash)}
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta
            title='Cash in/out'
            description={ppPrice(drawer.transferCash)}
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta
            title='Expected cheque in Drawer'
            description={ppPrice(totalCheque)}
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta
            title='Expected cash in Drawer'
            description={ppPrice(expectedCash)}
          />
        </List.Item>
        <List.Item>
          <List.Item.Meta title='Actual cash in Drawer' />
          <InputNumber
            value={actualAmount}
            formatter={DollarInputNumberFormatter}
            onChange={(value) => {
              setActualAmount(value);
            }}
            style={{ width: '150px' }}
            min={0}
            precision={2}
          />
        </List.Item>
        {actualAmount && shortCash > 0 && (
          <>
            <List.Item style={{ display: shortCash > 0 ? '' : 'none' }}>
              <List.Item.Meta title='Cash short (cash missing)' />
              <Text type='danger'>{ppPrice(shortCash)}</Text>
            </List.Item>
            <List.Item style={{ display: shortCash > 0 ? '' : 'none' }}>
              <List.Item.Meta title='Reason' />
              <Input
                value={reason}
                style={{ width: '150px' }}
                onChange={(e) => {
                  setReason(e.target.value);
                }}
              />
            </List.Item>
          </>
        )}
      </List>
      <Button
        disabled={shortCash > 0 && !reason}
        style={{ marginTop: 8 }}
        block
        danger={shortCash > 0}
        type='primary'
        onClick={async () => {
          try {
            if (!actualAmount) {
              throw new Error('Please set actual drawer amount.');
            }
            await runTransaction(db, async (transaction) => {
              const drawerRef = drawerRepository.document(drawer.key);
              const read = await (await transaction.get(drawerRef)).data();
              transaction.set(
                drawerRef,
                new Drawer('', {
                  salesCash: _.round(read!.salesCash, 2),
                  actualCash: _.round(actualAmount, 2),
                  reason: reason,
                }),
                {
                  merge: true,
                }
              );
            });
            history.push('.');
            Modal.destroyAll();
          } catch (e) {
            Notifications.error(e);
          }
        }}
      >
        End Drawer {actualAmount && `with ${ppPrice(actualAmount)}`}
      </Button>
    </>
  );
};

const CloseDrawerDialog = (
  companyId: string,
  storeId: string,
  history: any,
  drawer?: Drawer,
  drawerRepository?: Repository<Drawer>
) => {
  if (drawer && drawerRepository) {
    Modal.success({
      centered: true,
      closable: true,
      maskClosable: false,
      title: 'Close Register',
      okText: null,
      icon: null,
      okButtonProps: {
        style: {
          display: 'none',
        },
      },
      content: (
        <CloseDrawer
          drawer={drawer}
          drawerRepository={drawerRepository}
          history={history}
          companyId={companyId}
          storeId={storeId}
        />
      ),
    });
  }
};

export { CloseDrawerDialog };
